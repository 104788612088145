<template>
    <div id="app">
        <!-- NavBar -->
        <NavBar />
        <!-- Main content -->
        <div class="main-wrapper">
            <transition name="page" mode="out-in">
                <router-view />
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from '@/components/NavBar.vue'

export default {
    components: {
        NavBar,
    },

    computed: {
        ...mapGetters(['seo']),
    },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },

    mounted() {
        if (window.location.href.includes('//qolab.ca')) {
            window.location.href = window.location.href.replace('//qolab.ca', '//www.qolab.ca')
        }
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

.page-enter-active,
.page-leave-active {
    transition-duration: 1.471s;
    transition-property: opacity, transform;
    // transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);

    [hero-transition-group] {
        transition: opacity 1s cubic-bezier(0.35, 0, 0.22, 1) 0.45s,
            transform 2.1s cubic-bezier(0.35, 0, 0.22, 1) 0s;
    }
}

.page-enter,
.page-leave-active {
    [hero-transition-group] {
        transform: translate3d(0, 45px, 0);
        opacity: 0;
    }
}
</style>
