import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomePage-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
    {
        path: '/en',
        name: 'HomePage-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/en/Home.vue'),
        meta: { lang: 'en', json: 'home' },
    },
    {
        path: '/creativite',
        name: 'Creativite-fr',
        component: () => import(/* webpackChunkName: "creativite" */ '../views/Creativite.vue'),
        meta: { lang: 'fr', json: 'creativite' },
    },
    {
        path: '/contenu',
        name: 'Contenu-fr',
        component: () => import(/* webpackChunkName: "contenu" */ '../views/Contenu.vue'),
        meta: { lang: 'fr', json: 'contenu' },
    },
    {
        path: '/production',
        name: 'Production-fr',
        component: () => import(/* webpackChunkName: "production" */ '../views/Production.vue'),
        meta: { lang: 'fr', json: 'production' },
    },
    {
        path: '/carrieres',
        name: 'Carrieres-fr',
        component: () => import(/* webpackChunkName: "carrieres" */ '../views/carrieres/Index.vue'),
        meta: { lang: 'fr', json: 'carrieres' },
    },
    {
        path: '/carrieres/:slug',
        name: 'carrieresEntry-fr',
        component: () => import(/* webpackChunkName: "carrieresEntry" */ '../views/carrieres/Entry.vue'),
        // meta: { lang: 'fr', json: 'carrieresEntry', slug: true },
        meta: { lang: 'fr', json: 'carrieres' },
    },
    // Redirect /en/ to HomePage
    {
        path: '/en/',
        redirect: '/',
    },
    {
        path: '*',
        name: 'p404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
})

router.beforeEach((to, from, next) => {
    const { lang } = to.meta
    document.documentElement.lang = lang
    i18n.locale = lang

    if (Store.getters['locale'] !== lang) {
        Store.dispatch('getGlobals')
        Store.dispatch('setLocale', lang)
    }

    setTimeout(() => {
        // Store.dispatch('resetContent')

        let type = 'pages'
        const { meta, params } = to
        let slug = meta.json
        let item = ''

        if (meta.slug) {
            item = params.slug
            type = '' // Use slug and ignore type
        }

        Store.dispatch('getContent', { lang: meta.lang, slug, type, item })
    }, 425)

    next()
})

export default router
