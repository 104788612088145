<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="90.658" viewBox="141 28 125.658 40">
        <g data-name="Group 289">
            <g data-name="Group 1" transform="translate(141 28)" clip-path='url("#a")'>
                <path
                    d="m71.858 5.834-.01 2.515 11.441-.002a13.27 13.27 0 0 1 2.881.286c3.097.691 4.683 2.604 4.998 5.424a9.945 9.945 0 0 1 .059 1.103l-.001.69a.489.489 0 0 1-.06.237.49.49 0 0 1-.737.133.51.51 0 0 1-.107-.13c-2.032-3.481-5.73-5.81-9.96-5.81a11.797 11.797 0 0 0-11.618 11.822 12.601 12.601 0 0 0 0 .155 12.09 12.09 0 0 0 4.528 9.49 11.348 11.348 0 0 0 7.09 2.488A11.541 11.541 0 0 0 90.3 28.46a.504.504 0 0 1 .123-.142c.294-.231.78-.042.78.385l-.002.781.002 4.06h2.6V15.943a15.768 15.768 0 0 0-.43-3.887c-1.094-4.28-4.307-6.234-10.026-6.234l-11.354.012a18031.852 18031.852 0 0 0-.135 0Zm2.078 22.797a10.117 10.117 0 0 0 7.349 3.105 10.521 10.521 0 0 0 .952-.043 9.66 9.66 0 0 0 8.975-9.436 9.212 9.212 0 0 0-2.579-6.373 10.117 10.117 0 0 0-7.348-3.106 10.522 10.522 0 0 0-.953.043 9.66 9.66 0 0 0-8.975 9.436 9.212 9.212 0 0 0 2.579 6.374Z"
                    fill="#1e1e1e"
                    fill-rule="evenodd"
                    data-name="Path 4"
                />
                <path
                    d="M62.035 0h2.518v33.542h-2.518V0z"
                    fill="#1e1e1e"
                    fill-rule="evenodd"
                    data-name="Rectangle 10"
                />
                <path
                    d="M53.85 9.762c-2.617-2.595-5.862-3.92-9.65-3.94a17.586 17.586 0 0 0-2.572.196 13.106 13.106 0 0 0-3.289.95 12.91 12.91 0 0 0-7.016 7.019 16.507 16.507 0 0 0-1.113 3.957c-.447 3.066.086 6.088 1.597 9.025 1.222 2.374 3.072 4.254 5.5 5.588 2.03 1.116 4.267 1.678 6.668 1.678a16.197 16.197 0 0 0 1.845-.107c3.3-.376 6.08-1.76 8.261-4.114 3.113-3.356 4.355-7.482 3.697-12.27a20.002 20.002 0 0 0-.089-.579 13.327 13.327 0 0 0-3.838-7.403Zm.46 15.235a12.255 12.255 0 0 0 .972-4.88 16.778 16.778 0 0 0-.057-1.338 12.543 12.543 0 0 0-.816-3.578 11.982 11.982 0 0 0-.377-.863 10.164 10.164 0 0 0-2.273-3.09c-1.662-1.523-3.605-2.448-5.777-2.75-.68-.094-1.339-.142-1.983-.142-.97 0-1.905.108-2.826.323a10.39 10.39 0 0 0-5.6 3.413c-1.49 1.728-2.395 3.778-2.69 6.093a13.661 13.661 0 0 0 .211 4.78 11.975 11.975 0 0 0 1.64 3.88 10.33 10.33 0 0 0 4.62 3.919 11.55 11.55 0 0 0 4.622.946 13.078 13.078 0 0 0 1.912-.144 10.474 10.474 0 0 0 6.66-3.732 11.482 11.482 0 0 0 1.762-2.837Z"
                    fill="#1e1e1e"
                    fill-rule="evenodd"
                    data-name="Path 3"
                />
                <path
                    d="M101.006 12.428V.002h-2.54V33.54h2.54v-5.598a.502.502 0 0 1 .055-.236.5.5 0 0 1 .773-.128.522.522 0 0 1 .107.146 15.283 15.283 0 0 0 .499.912c.792 1.34 1.86 2.686 3.072 3.574a7.192 7.192 0 0 0 .177.125 11.377 11.377 0 0 0 6.567 1.902h.003a14.353 14.353 0 0 0 1.752-.11 12.163 12.163 0 0 0 7.014-3.266c3.079-2.904 4.633-6.618 4.633-11.058a20.206 20.206 0 0 0-.068-1.64 14.154 14.154 0 0 0-2.06-6.274 12.497 12.497 0 0 0-4.008-4.13 12.145 12.145 0 0 0-1.446-.78 12.696 12.696 0 0 0-5.281-1.195 11.695 11.695 0 0 0-2.035.179 11.38 11.38 0 0 0-3.144 1.039c-2.823 1.417-4.613 3.78-5.613 5.536a15.783 15.783 0 0 0-.076.136c-.218.391-.77.295-.895-.082a.514.514 0 0 1-.026-.164Zm6.707-3.2a11.283 11.283 0 0 1 4.395-.898 12.01 12.01 0 0 1 3.123.424 13.44 13.44 0 0 1 .327.092 9.728 9.728 0 0 1 4.934 3.359 11.023 11.023 0 0 1 2.273 5.06 17.522 17.522 0 0 1 .224 1.952c.162 2.89-.49 5.42-1.974 7.691a10.563 10.563 0 0 1-3.47 3.388 10.49 10.49 0 0 1-4.088 1.354 12.646 12.646 0 0 1-1.55.097 10.47 10.47 0 0 1-5.505-1.507 10.445 10.445 0 0 1-3.43-3.396 12.851 12.851 0 0 1-1.672-4.05 10.915 10.915 0 0 1-.266-2.26 23.393 23.393 0 0 1 0-.031c0-3.083.608-5.368 1.97-7.405a10.406 10.406 0 0 1 4.71-3.87Z"
                    fill="#1e1e1e"
                    fill-rule="evenodd"
                    data-name="Path 2"
                />
                <path
                    d="M24.652 27.574V40h2.539V6.461h-2.538v5.599a.502.502 0 0 1-.056.236.5.5 0 0 1-.773.128.522.522 0 0 1-.107-.146 15.281 15.281 0 0 0-.499-.912c-.83-1.405-1.964-2.817-3.25-3.7a11.377 11.377 0 0 0-6.567-1.901H13.4a14.353 14.353 0 0 0-1.753.11A12.163 12.163 0 0 0 4.632 9.14a14.562 14.562 0 0 0-4.63 10.85C0 20.06 0 20.129 0 20.198a20.205 20.205 0 0 0 .068 1.64 14.153 14.153 0 0 0 2.059 6.275 12.497 12.497 0 0 0 4.009 4.13 12.146 12.146 0 0 0 1.446.78c2.273 1.034 4.744 1.47 7.315 1.016A11.38 11.38 0 0 0 18.041 33c2.897-1.454 4.707-3.904 5.69-5.673a.5.5 0 0 1 .122-.147c.296-.24.799-.043.799.394Zm-11.103 4.098a11.283 11.283 0 0 0 4.395-.898 10.406 10.406 0 0 0 4.71-3.87 11.297 11.297 0 0 0 .484-.792c1.023-1.84 1.486-3.923 1.486-6.613a23.631 23.631 0 0 0 0-.032 10.914 10.914 0 0 0-.266-2.258 12.851 12.851 0 0 0-1.672-4.05 10.445 10.445 0 0 0-3.43-3.398 10.47 10.47 0 0 0-5.505-1.506 12.646 12.646 0 0 0-1.55.097 10.49 10.49 0 0 0-4.089 1.354 10.563 10.563 0 0 0-3.469 3.388 12.168 12.168 0 0 0-1.999 6.838c0 .28.009.565.025.852a17.521 17.521 0 0 0 .224 1.952 11.023 11.023 0 0 0 2.273 5.061 9.728 9.728 0 0 0 4.934 3.359 13.44 13.44 0 0 0 .327.092 12.01 12.01 0 0 0 3.122.424Z"
                    fill="#1e1e1e"
                    fill-rule="evenodd"
                    data-name="Path 1"
                />
            </g>
        </g>
        <defs>
            <clipPath id="a"><path d="M0 0h125.658v40H0V0z" data-name="Rectangle 11" /></clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'Logo',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
