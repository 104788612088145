<template>
    <div class="util" :class="{ 'theme-switcher-active': $route.name && $route.name.includes('carri') }">
        <nav class="main-nav site-max-width-large" :class="{ open: menuIsOpen, 'is-en': $route.meta.lang == 'en' }">
            <div class="container site-max-width">
                <div class="logo">
                    <router-link :to="{ name: 'HomePage-fr' }"><Logo /></router-link>
                </div>
                <div v-if="$route.meta && $route.meta.lang == 'fr'" class="title" @click.prevent="$router.push('/')">Bureau créatif</div>
                <div v-else class="title is-en" @click.prevent="$router.push('/en/')">The Creative Office</div>

                <div class="links" v-if="$route.meta && $route.meta.lang == 'fr'">
                    <router-link :to="{ name: 'Creativite-fr' }" class="link" title="Créativité"
                        >Créativité</router-link
                    >
                    <router-link :to="{ name: 'Contenu-fr' }" class="link" title="Contenu"
                        >Contenu</router-link
                    >
                    <router-link :to="{ name: 'Production-fr' }" class="link" title="Production"
                        >Production</router-link
                    >
                    <router-link :to="{ name: 'Carrieres-fr' }" class="link" title="Carrières"
                        >Carrières</router-link
                    >
                </div>
                <div v-if="$route.meta && $route.meta.lang == 'fr'" id="hamburger" @click.prevent.stop="toggleMenu()" :class="{ open: menuIsOpen }">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <a v-if="$route.meta && $route.meta.lang == 'fr'" href="/en/" class="lang">
                    <span class="separator"></span> EN
                </a>
                <a v-else href="/" class="lang">
                    <span class="separator"></span> FR
                </a>
            </div>
        </nav>

        <section class="menu-mobile" :class="{ open: menuIsOpen }" @click.prevent="toggleMenu(false)">
            <!-- <router-link :to="{ name: 'HomePage-fr' }"><Logo class="logo" /></router-link> -->
            <nav>
                <!-- <router-link :to="{ name: 'HomePage-' + $route.meta.lang }">{{ $t('Accueil') }}</router-link> -->
                <router-link :to="{ name: 'Creativite-' + $route.meta.lang }">{{ $t('Créativité') }}</router-link>
                <router-link :to="{ name: 'Contenu-' + $route.meta.lang }">{{ $t('Contenu') }}</router-link>
                <router-link :to="{ name: 'Production-' + $route.meta.lang }">{{ $t('Production') }}</router-link>
                <router-link :to="{ name: 'Carrieres-' + $route.meta.lang }">{{ $t('Carrières') }}</router-link>
            </nav>
        </section>
    </div>
</template>

<script>
import Logo from '@/components/svgs/Logo.vue'

export default {
    name: 'NavBar',

    components: {
        Logo,
    },

    data() {
        return {
            menuIsOpen: false,
            lastScrollY: 0,
        }
    },

    methods: {
        toggleMenu(value = 'none') {
            // console.log(value)
            if (value !== 'none') {
                this.menuIsOpen = value
            } else {
                this.menuIsOpen = !this.menuIsOpen
            }
        }
    },

    mounted() {
        // On Window scroll
        const app = document.querySelector('#app')
        const navbar = document.querySelector('.main-nav')
        const navBarHeight = navbar.offsetHeight

        window.onscroll = () => {
            // On scroll down
            if (window.scrollY > 0) {
                // Add class to navbar
                navbar.classList.add('is-fixed')
                app.style.paddingTop = `${navBarHeight}px`
            } else {
                // Remove class from navbar
                navbar.classList.remove('is-fixed')
                app.style.paddingTop = `${0}px`
            }

            // Detect scroll direction
            if (window.scrollY > this.lastScrollY) {
                // Scrolling down
                navbar.classList.add('is-scrolling-down')
                navbar.classList.remove('is-scrolling-up')
            } else {
                // Scrolling up
                navbar.classList.remove('is-scrolling-down')
                navbar.classList.add('is-scrolling-up')
            }

            this.lastScrollY = window.scrollY
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
